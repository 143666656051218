html,
body,
#root {
    height: 100%;
    margin: 0;
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #0003;
}
